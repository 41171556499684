import { graphql, useStaticQuery } from "gatsby"

export default function useHomeData() {
  const data = useStaticQuery(graphql`
    query getFaqData {
      markdownRemark(frontmatter: {url: {eq: "faq"}}) {
    id
    frontmatter {
      title
      accordion {
        content
        header
      }
    }
  }
  }

  `)
  return data.markdownRemark
}
