import React from "react"
import Layout from "../../components/Layout"
import useFaqData from "../../static_queries/useFaqData"
import Accordion from "../../components/Accordion"

export default function FAQ() {
  const { frontmatter } = useFaqData()
  return (
    <Layout title={frontmatter.title}>
      <Accordion accordion={frontmatter.accordion}/>
    </Layout>
  )
}
